import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const commisionValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {

  const min = control.get("min").value;
  const max = control.get("max").value;
  console.log(min)
  console.log(max)
  let result =
  min && max && min > max
      ? {
          forbiddenCommision: true,
        }
      : null;
console.log(result)
  return result;
};