/**
 * OskPack.WebApi.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from "@angular/common/http";
import { CustomHttpUrlEncodingCodec } from "../encoder";

import { Observable } from "rxjs";

import { AddDealerRQ } from "../model/addDealerRQ";
import { DeleteDealerRQ } from "../model/deleteDealerRQ";
import {
  DeleteDealerTaxRP,
  DeleteDealerTaxRQ,
} from "./../model/deleteDealerTaxRQ";
import { GetCurrencyDealerSettingRQ } from "../model/getCurrencyDealerSettingRQ";
import { ListDealerTaxRQ, ListDealerTaxRP } from "../model/listDealerTaxRQ";
import { AddDealerTaxRQ, UpdateDealerTaxRQ, AddDealerTaxRP } from "../model/addDealerTaxRQ";
import { GetDealerModelRP } from "../model/getDealerModelRP";
import { GetDealerModelRQ } from "../model/getDealerModelRQ";
import { ListCurrencyDealerSettingRP } from "../model/listCurrencyDealerSettingRP";
import { ListCurrencyDealerSettingRQ } from "../model/listCurrencyDealerSettingRQ";
import { ListDealerRP, ListDealersRP } from "../model/listDealerRP";
import { Unit } from "../model/unit";
import { UpdateDealerRQ } from "../model/updateDealerRQ";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable()
export class DealerService {
  protected basePath = "/";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = "multipart/form-data";
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerAddPost(
    body?: AddDealerRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Unit>;
  public apiDealerAddPost(
    body?: AddDealerRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Unit>>;
  public apiDealerAddPost(
    body?: AddDealerRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Unit>>;
  public apiDealerAddPost(
    body?: AddDealerRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Unit>(
      "post",
      `${this.basePath}/api/Dealer/Add`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  //add dealer tax
  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerTaxAddPost(
    body?: AddDealerTaxRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<AddDealerTaxRP>;
  public apiDealerTaxAddPost(
    body?: AddDealerTaxRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<AddDealerTaxRP>>;
  public apiDealerTaxAddPost(
    body?: AddDealerTaxRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<AddDealerTaxRP>>;
  public apiDealerTaxAddPost(
    body?: AddDealerTaxRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<AddDealerTaxRP>(
      "post",
      `${this.basePath}/api/Dealer/AddDealerTax`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerTaxUpdatePost(
    body?: UpdateDealerTaxRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Unit>;
  public apiDealerTaxUpdatePost(
    body?: UpdateDealerTaxRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Unit>>;
  public apiDealerTaxUpdatePost(
    body?: UpdateDealerTaxRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Unit>>;
  public apiDealerTaxUpdatePost(
    body?: UpdateDealerTaxRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Unit>(
      "post",
      `${this.basePath}/api/Dealer/UpdateDealerTax`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerDeletePost(
    body?: DeleteDealerRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Unit>;
  public apiDealerDeletePost(
    body?: DeleteDealerRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Unit>>;
  public apiDealerDeletePost(
    body?: DeleteDealerRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Unit>>;
  public apiDealerDeletePost(
    body?: DeleteDealerRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Unit>(
      "post",
      `${this.basePath}/api/Dealer/Delete`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  //dealer tax
  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerTaxDeletePost(
    body?: DeleteDealerTaxRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<DeleteDealerTaxRP>;
  public apiDealerTaxDeletePost(
    body?: DeleteDealerTaxRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<DeleteDealerTaxRP>>;
  public apiDealerTaxDeletePost(
    body?: DeleteDealerTaxRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<DeleteDealerTaxRP>>;
  public apiDealerTaxDeletePost(
    body?: DeleteDealerTaxRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<DeleteDealerTaxRP>(
      "post",
      `${this.basePath}/api/Dealer/DeleteDealerTax`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerGetListTaxPost(
    body?: ListDealerTaxRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ListDealerTaxRP>;
  public apiDealerGetListTaxPost(
    body?: ListDealerTaxRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ListDealerTaxRP>>;
  public apiDealerGetListTaxPost(
    body?: ListDealerTaxRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ListDealerTaxRP>>;
  public apiDealerGetListTaxPost(
    body?: ListDealerTaxRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<ListDealerTaxRP>(
      "post",
      `${this.basePath}/api/Dealer/DealerTaxList`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerGetCurrencyDealerSettingPost(
    body?: GetCurrencyDealerSettingRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ListCurrencyDealerSettingRP>;
  public apiDealerGetCurrencyDealerSettingPost(
    body?: GetCurrencyDealerSettingRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ListCurrencyDealerSettingRP>>;
  public apiDealerGetCurrencyDealerSettingPost(
    body?: GetCurrencyDealerSettingRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ListCurrencyDealerSettingRP>>;
  public apiDealerGetCurrencyDealerSettingPost(
    body?: GetCurrencyDealerSettingRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<ListCurrencyDealerSettingRP>(
      "post",
      `${this.basePath}/api/Dealer/GetCurrencyDealerSetting`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerGetPost(
    body?: GetDealerModelRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<GetDealerModelRP>;
  public apiDealerGetPost(
    body?: GetDealerModelRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<GetDealerModelRP>>;
  public apiDealerGetPost(
    body?: GetDealerModelRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<GetDealerModelRP>>;
  public apiDealerGetPost(
    body?: GetDealerModelRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<GetDealerModelRP>(
      "post",
      `${this.basePath}/api/Dealer/Get`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerListGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ListDealerRP>;
  public apiDealerListGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ListDealerRP>>;
  public apiDealerListGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ListDealerRP>>;
  public apiDealerListGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ListDealerRP>(
      "get",
      `${this.basePath}/api/Dealer/List`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealersListGet(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<ListDealersRP>;
  public apiDealersListGet(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<ListDealersRP>>;
  public apiDealersListGet(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<ListDealersRP>>;
  public apiDealersListGet(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ListDealersRP>(
      "get",
      `${this.basePath}/api/Dealer/ListDealers`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerSaveCurrencyDealerSettingPost(
    body?: ListCurrencyDealerSettingRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Unit>;
  public apiDealerSaveCurrencyDealerSettingPost(
    body?: ListCurrencyDealerSettingRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Unit>>;
  public apiDealerSaveCurrencyDealerSettingPost(
    body?: ListCurrencyDealerSettingRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Unit>>;
  public apiDealerSaveCurrencyDealerSettingPost(
    body?: ListCurrencyDealerSettingRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Unit>(
      "post",
      `${this.basePath}/api/Dealer/SaveCurrencyDealerSetting`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiDealerUpdatePost(
    body?: UpdateDealerRQ,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Unit>;
  public apiDealerUpdatePost(
    body?: UpdateDealerRQ,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Unit>>;
  public apiDealerUpdatePost(
    body?: UpdateDealerRQ,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Unit>>;
  public apiDealerUpdatePost(
    body?: UpdateDealerRQ,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      "text/plain",
      "application/json",
      "text/json",
    ];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      "application/json",
      "text/json",
      "application/_*+json",
    ];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.request<Unit>(
      "post",
      `${this.basePath}/api/Dealer/Update`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
